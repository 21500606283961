<template>
  <div>
    <container-component>
      <router-view></router-view>
    </container-component>
  </div>
</template>

<script>
import ContainerComponent from "@/views/containerComponent";
export default {
  name: "viewRouter",
  components: { ContainerComponent },
};
</script>
